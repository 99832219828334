import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from '@shared/services/api.service';
import { DataResponse, SuccessResponse, ListResponse } from '@shared/models/response.model';
import {Cacheable, CacheBuster} from 'ts-cacheable';
import {rfidUser} from '@shared/models/rfid-user.model';
@Injectable({
  providedIn: 'root'
})
export class RfidService {

  private isFieldFocused=new BehaviorSubject<boolean>(false); // tracking input focus
  
  private rfidData=new BehaviorSubject<string|null>(null); // tracking rfid data
  
  isFieldFocused$=this.isFieldFocused.asObservable();
  rfidData$=this.rfidData.asObservable();

  setFieldFocused(isFocused:boolean):void
  {
    this.isFieldFocused.next(isFocused);
  }

  setRFIDData(data:string)
  {
    this.rfidData.next(data);
  }

  




  constructor(private api: ApiService) { }
}
