import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { AuthRoutingModule } from '@auth/auth-routing.module';
import { LoginComponent } from '@auth/pages/login/login.component';
import { LogoutComponent } from '@auth/pages/logout/logout.component';
import { IsLoggedInGuard } from '@shared/guards/is-logged-in.guard';
import { IsLoggedOutGuard } from '@shared/guards/is-logged-out.guard';
import { AuthComponent } from '@auth/pages/auth/auth.component';
import { ForgetPasswordDialogComponent } from './pages/forget-password-dialog/forget-password-dialog.component';
import {CaptchaDialogComponent} from '@auth/pages/captcha-dialog/captcha-dialog.component';
import { RfidComponent } from './pages/rfid/rfid.component';


@NgModule({
  imports: [
    SharedModule,
    AuthRoutingModule,
  ],
  declarations: [
    AuthComponent,
    LoginComponent,
    LogoutComponent,
    ForgetPasswordDialogComponent,
    CaptchaDialogComponent,
    RfidComponent
    
  ],
  providers: [
    IsLoggedInGuard,
    IsLoggedOutGuard,
  ]
})
export class AuthModule {}
