import { NgModule } from '@angular/core';
import { HazardousSubstanceStatementFilterComponent } from './hazardous-substance-statement-filter.component';
import {FilterModule} from '@shared/components/filter/filter.module';
import {
  HazardousSubstanceStatementSelectModule
} from '@shared/components/hazardous-substance-statement-select/hazardous-substance-statement-select.module';



@NgModule({
  declarations: [
    HazardousSubstanceStatementFilterComponent
  ],
  imports: [
    HazardousSubstanceStatementSelectModule,
    FilterModule,
  ],
  exports: [
    HazardousSubstanceStatementFilterComponent
  ]
})
export class HazardousSubstanceStatementFilterModule { }
