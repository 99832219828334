import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GhsCategoryFilterComponent } from './ghs-category-filter.component';
import {FilterModule} from '@shared/components/filter/filter.module';
import {GhsCategorySelectModule} from '@shared/components/ghs-category-select/ghs-category-select.module';
import {
  HazardousSubstanceStatementSelectModule
} from '@shared/components/hazardous-substance-statement-select/hazardous-substance-statement-select.module';



@NgModule({
  declarations: [
    GhsCategoryFilterComponent
  ],
  imports: [
    FilterModule,
    GhsCategorySelectModule,
    HazardousSubstanceStatementSelectModule
  ],
  exports: [
    GhsCategoryFilterComponent
  ]
})
export class GhsCategoryFilterModule { }
