import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WaterHazardClassFilterComponent } from './water-hazard-class-filter.component';
import {FormsModule} from '@angular/forms';
import {MaterialModule} from '@shared/material.module';
import {PipesModule} from '@shared/pipes/pipes.module';
import {FilterModule} from '@shared/components/filter/filter.module';
import {WaterHazardClassSelectModule} from '@shared/components/water-hazard-class-select/water-hazard-class-select.module';



@NgModule({
  declarations: [
    WaterHazardClassFilterComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    PipesModule,
    FilterModule,
    WaterHazardClassSelectModule
  ],
  exports: [
    WaterHazardClassFilterComponent
  ]
})
export class WaterHazardClassFilterModule { }
