import { NgModule } from '@angular/core';
import { HazardIconFilterComponent } from './hazard-icon-filter.component';
import {FilterModule} from '@shared/components/filter/filter.module';
import {HazardIconSelectModule} from '@shared/components/hazard-icon-select/hazard-icon-select.module';



@NgModule({
  declarations: [
    HazardIconFilterComponent
  ],
  imports: [
    HazardIconSelectModule,
    FilterModule
  ],
  exports: [
    HazardIconFilterComponent
  ]
})
export class HazardIconFilterModule { }
