import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HazardAssessmentDialogComponent } from './hazard-assessment-dialog.component';
import { MaterialModule } from '@shared/material.module';
import { PipesModule } from '@shared/pipes/pipes.module';
import { FilterPanelModule } from '@shared/components/filter-panel/filter-panel.module';
import { HazardAssessmentTemplateCategoryFilterModule } from '@shared/components/hazard-assessment-template-category-filter/hazard-assessment-template-category-filter.module';
import { HazardAssessmentTemplateStateFilterModule } from '@shared/components/hazard-assessment-template-state-filter/hazard-assessment-template-state-filter.module';
import { LoadingOverlayModule } from '@shared/components/loading-overlay/loading-overlay.module';
import {FlexLayoutModule, FlexModule} from '@angular/flex-layout';
import {EmptyMessageModule} from '@shared/components/empty-message/empty-message.module';
import {LoadingSpinnerModule} from '@shared/components/loading-spinner/loading-spinner.module';
import {DepartmentFilterModule} from '@shared/components/department-filter/department-filter.module';
import {EmployeeActivityFilterModule} from '@shared/components/employee-activity-filter/employee-activity-filter.module';
import {EmployeeCategoryFilterModule} from '@shared/components/employee-category-filter/employee-category-filter.module';
import {EmployeeEditingFilterModule} from '@shared/components/employee-editing-filter/employee-editing-filter.module';
import {ModuleFilterModule} from '@shared/components/module-filter/module-filter.module';
import {ProfileFilterModule} from '@shared/components/profile-filter/profile-filter.module';
import {QualificationFilterModule} from '@shared/components/qualification-filter/qualification-filter.module';
import {RoleFilterModule} from '@shared/components/role-filter/role-filter.module';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    PipesModule,
    FilterPanelModule,
    HazardAssessmentTemplateCategoryFilterModule,
    HazardAssessmentTemplateStateFilterModule,
    LoadingOverlayModule,
    FlexModule,
    FlexLayoutModule,
    EmptyMessageModule,
    LoadingSpinnerModule,
    DepartmentFilterModule,
    EmployeeActivityFilterModule,
    EmployeeCategoryFilterModule,
    EmployeeEditingFilterModule,
    ModuleFilterModule,
    ProfileFilterModule,
    QualificationFilterModule,
    RoleFilterModule,
  ],
    declarations: [
        HazardAssessmentDialogComponent,
    ],
    exports: [
        HazardAssessmentDialogComponent,
    ]
})
export class HazardAssessmentDialogModule {}
