import { Injectable } from '@angular/core';
import { ApiService } from './api.service';


interface UsertDetails
{
  jwt:string;
  companySettings:any;
  userDetail:any;
  permissions:any;
  refreshToken:string;
}


@Injectable({
  providedIn: 'root'
})
export class ImpersonationService {
  private readonly STORAGE_KEY = 'impersonation_backup';
  private readonly BACKUP_KEY = 'impersonation_backup';
  private readonly KEYS_TO_BACKUP = ['jwt', 'permissions', 'companySettings', 'userDetail', 'refreshToken'];

  constructor(private api: ApiService) {}

  startImpersonation() {
    if(!this.isImpersonating())
    {
    this.backupLocalStorage();
    }else
    {
      throw Error ('you can not double impersonate');
    }
  }

  stopImpersonation() {

   this.restoreLocalStorage();
   sessionStorage.removeItem('impersonation');


  }

  isImpersonating(): boolean {
    return localStorage.getItem(this.STORAGE_KEY) !== null;
  }

  getImpersonatorId(): number | null {
    const data = localStorage.getItem(this.STORAGE_KEY);
    return data ? JSON.parse(data).adminId : null;
  }

  private backupLocalStorage() {
    const backup: Record<string, string> = {};

    this.KEYS_TO_BACKUP.forEach((key) => {
      const value = localStorage.getItem(key);
      if (value !== null) {
        backup[key] = value;
      }
    });

    localStorage.setItem(this.BACKUP_KEY, JSON.stringify(backup));
  }

  private setImpersonatedUser(userDetails:UsertDetails):void
  {
    this.KEYS_TO_BACKUP.forEach(key=>{
      const value=userDetails[key as keyof UsertDetails];
      if(value)
      {
        localStorage.setItem(key,typeof value === 'string' ? value: JSON.stringify(value)
        );
      }
    })
  }

  private restoreLocalStorage() {
    const backupData = localStorage.getItem(this.BACKUP_KEY);

    if (!backupData) {
        return;
    }

    try {
        const parsedBackup = JSON.parse(backupData);


        Object.keys(parsedBackup).forEach((key) => {

            if (key !== this.BACKUP_KEY) {

                if (localStorage.getItem(key)) {
                    localStorage.removeItem(key);
                }

                localStorage.setItem(key, parsedBackup[key]);
            }
        });


        localStorage.removeItem(this.BACKUP_KEY);

    } catch (error) {
        throw error ('some thing happened',error);
    }
}

 clearLocalStorage()
{
  try
  {
    localStorage.clear();
  }catch
  {

  }
}


}
