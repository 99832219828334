import {Injectable} from '@angular/core';
import {Observable, Subject, throwError} from 'rxjs';
import {DataResponse, SuccessResponse, ListResponse} from '@shared/models/response.model';
import {ApiService} from '@shared/services/api.service';
import {Cacheable, CacheBuster} from 'ts-cacheable';
import {InstructionListItem} from '@shared/models/instruction-list-item.model';
import {Instruction} from '@shared/models/instruction.model';
import {InstructionDetails} from '@shared/models/instruction-details.model';
import {catchError, map, tap} from 'rxjs/operators';
import {HttpHeaders, HttpClient, HttpResponse} from '@angular/common/http';
import {AuthService} from './auth.service';

interface BulkPrintResponse {
  downloadUrl: string;
  failedUrls?: string;
}

const cacheNotifier: Subject<void> = new Subject();

@Injectable({
  providedIn: 'root'
})
export class InstructionService {

  constructor(private api: ApiService, private authService: AuthService) {
  }

  @Cacheable({
    cacheBusterObserver: cacheNotifier
  })
  getList(params?: object): Observable<ListResponse<InstructionListItem>> {
    return this.api.get(`/instructions`, params);
  }

  @Cacheable({
    cacheBusterObserver: cacheNotifier
  })
  getDetailsList(params?: object): Observable<ListResponse<InstructionDetails>> {
    return this.api.get(`/instruction-list`, params);
  }

  getById(id: number): Observable<Instruction> {
    return this.api.get(`/instruction/${id}`);
  }

  getDetails(instructionId: number): Observable<InstructionDetails> {
    return this.api.get(`/instructionDetails/${instructionId}`)
      .pipe(map((details: InstructionDetails) => {
        details.instruction.blockSettings = Object.values(details.instruction.blockSettings);

        return details;
      }));
  }

  getPrintURL(instructionId: number, departmentId: number, jwt: string) {
    return `${this.api.apiURL}/instruction/${instructionId}/print?sectionId=${departmentId}&jwt=${jwt}`;
  }

  copy(instructionId: number, data: object): Observable<DataResponse<Instruction>> {
    return this.api.post(`/instruction/copy/${instructionId}`, data);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  create(data: object): Observable<DataResponse<Instruction>> {
    return this.api.post(`/instruction`, data);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  createFromHazardAssessmentId(hazardAssessmentId: number, data: object): Observable<DataResponse<Instruction>> {
    return this.api.post(`/instruction/createFromAssessment/${hazardAssessmentId}`, data);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  createFromPatternInstructionId(data: object): Observable<DataResponse<Instruction>> {
    return this.api.post(`/instruction/createFromPattern`, data);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  update(id: number, data: object): Observable<DataResponse<Instruction>> {
    return this.api.put(`/instruction/${id}`, data);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  generateTopic(id: number): Observable<DataResponse<Instruction>> {
    return this.api.put(`/instruction/${id}/topic`);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  delete(id: number): Observable<SuccessResponse> {
    return this.api.delete(`/instruction/${id}`);
  }

  getExportURL(filter: object, sortingParams: object, jwt: string) {
    return `${this.api.apiURL}/instructions/export?filter=${JSON.stringify(filter)}&${this.api.getSortingStringForExport(sortingParams)}&jwt=${jwt}`;
  }

  static clearCache() {
    cacheNotifier.next();
  }

  getBulkPrintURL(instructionIds: number[], departmentIds: number[]): string[] {
    return instructionIds.map((instructionId, index) =>
      `${this.api.apiURL}/instruction/${instructionId}/editView1?sectionId=${departmentIds[index]}`
    );
  }

  generateBulkPDF(
    instructionIds: number[],
    departmentIds: number[]
  ): Observable<void> {


    if (!instructionIds.length || !departmentIds.length) {

      return throwError(() => new Error('Instruction IDs and Department IDs cannot be empty.'));
    }

    if (instructionIds.length !== departmentIds.length) {

      return throwError(() => new Error('Instruction IDs and Department IDs must have the same length.'));
    }


    const urls = this.getBulkPrintURL(instructionIds, departmentIds);
    const payload = {urls};

    return this.api.post<BulkPrintResponse>('/instruction/bulkPrint', payload, {
      headers: new HttpHeaders({
        'Authorization': `Bearer ${this.authService.getJwtToken()}`,
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Cookie': `PHPSESSID=${this.authService.getPhpSessId()}`
      })
    }).pipe(
      map((response: BulkPrintResponse): void => {

        if (!response.downloadUrl) {

          throw new Error('No download URL received from server.');
        }

        window.open(response.downloadUrl, '_blank');

      }),
      catchError((error: any) => {

        let errorMessage = 'Failed to generate bulk PDF.';
        if (error.error && error.error.error) {
          errorMessage = error.error.error;
        }

        return throwError(() => new Error(errorMessage));
      })
    );
  }

}
