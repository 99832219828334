import {Component, Input, OnInit} from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { AuthState } from '@shared/store/auth/auth.state';
import { FrontendState } from '@frontend/store/frontend/frontend.state';
import { ToggleSidenav } from '@frontend/store/frontend/frontend.actions';
import { BreakpointState } from '@shared/store/breakpoint/breakpoint.state';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { Breakpoints } from '@shared/models/breakpoints.model';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslationService } from '@shared/services/translation.service';
import {ActivatedRoute, Router} from '@angular/router';
import {InfoboxDialogComponent} from '@shared/components/infobox-dialog/infobox-dialog.component';
import {SaveSettings, SetSettings} from '@frontend/store/settings/settings.actions';
import {ThemeState} from '@shared/store/theme/theme.state';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {LanguageState} from '@shared/store/language/language.state';
import {isMobileViewport} from '@shared/utils/utils';
import {Location} from '@angular/common';
import {AssetService} from '@shared/services/asset.service';
import {DepartmentService} from '@shared/services/department.service';
import {Department} from '@shared/models/department.model';
import {HttpClient} from '@angular/common/http';
import config from '../../../../assets/config/config-production.json';
import { ImpersonationService } from '@shared/services/impersonation.service';
import {AppConfigService} from '@shared/services/app-config.service';

@Component({
  selector: 'saf-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Select(LanguageState.currentLanguage) currentLanguage$;
  @Select(AuthState.hasAssignedModules) hasAssignedModules$;
  @SelectSnapshot(FrontendState.sidenavOpened) sidenavOpened;
  @Select(BreakpointState.isBreakpoint([Breakpoints.XSmall, Breakpoints.Small])) smallViewport$;
  public mobileViewport = false;
  @Input() headerColor: string;
  public category;
  public systemUser: boolean = false;
  public impersonate: boolean=false;
  public formGroup: any;
  companySettings = JSON.parse(localStorage.getItem('companySettings'));
  userDetails = JSON.parse(localStorage.getItem('userDetail'));
  department = new Department();
  public isBeta =  false;
  public canTranslate =  false;
  // public isBeta = config.hasOwnProperty('isBeta') ? config['isBeta'] : false;

  constructor(
    private store: Store,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private translationService: TranslationService,
    private departmentService: DepartmentService,
    private assetService: AssetService,
    public router: Router,
    public route: ActivatedRoute,
    public _location: Location,
    private impersonationService: ImpersonationService,
    public appConfigService: AppConfigService,
  ) {}

  toggleSidenav() {
    this.store.dispatch(new ToggleSidenav());
  }

  locationBack() {
    this._location.back();
    // this.router.navigate(['..'], {relativeTo: this.route});
  }

  ngOnInit() {
    this.canTranslate = this.appConfigService.appConfig.showBetaModule;
    this.mobileViewport = isMobileViewport();
    const user = this.store.selectSnapshot(AuthState.user);

    const language = this.store.selectSnapshot(LanguageState.currentLanguage);
    const theme = this.store.selectSnapshot(ThemeState.theme);

    this.formGroup = new UntypedFormGroup({
      language: new UntypedFormControl(language, Validators.required),
      theme: new UntypedFormControl(theme, Validators.required),
    });

    if (user.systemUser) {
      this.systemUser = true;
    }

     if(this.impersonationService.isImpersonating())
     {
       this.impersonate=true;
     }

    if (!this.systemUser) {
      this.departmentService.getById(this.companySettings.companyId)
        .subscribe((result) => {
          this.department = result;
        } );
    }

  }

  openInfoboxDialog() {
    const dialogConfig: MatDialogConfig = {
      panelClass: 'small-dialog',
      disableClose: false
    };
    this.dialog.open(InfoboxDialogComponent, dialogConfig);
  }

  avatarURL(url: string) {
    if (!this.userDetails) { return ''; }
    return this.assetService.getAssetURL(url);
  }

  changeTheme(event: any) {
    if (event.checked) {
      this.formGroup.get('theme').setValue({active: true, class: 'theme-dark', default: true, name: 'dark'});
    } else {
      this.formGroup.get('theme').setValue({active: true, class: 'theme-light', default: false, name: 'light'});
    }

    if (this.formGroup.valid) {
      this.store.dispatch([
        // new SetSettings(this.store.selectSnapshot(SettingsState.settingsForm).model),
        new SetSettings(this.formGroup.value),
        new SaveSettings(),
      ]);
    }
  }

  stopImpersonate()
  {
    this.impersonationService.stopImpersonation();
    window.location.reload();
  }

    protected readonly isMobileViewport = isMobileViewport;
}
