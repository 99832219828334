import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TankCodeSelectDialogComponent } from './tank-code-select-dialog.component';
import {MaterialModule} from '@shared/material.module';
import {FlexLayoutModule} from '@angular/flex-layout';
import {EmptyMessageModule} from '@shared/components/empty-message/empty-message.module';
import {LoadingSpinnerModule} from '@shared/components/loading-spinner/loading-spinner.module';
import {LoadingOverlayModule} from '@shared/components/loading-overlay/loading-overlay.module';
import {FilterPanelModule} from '@shared/components/filter-panel/filter-panel.module';
import {PipesModule} from '@shared/pipes/pipes.module';



@NgModule({
  declarations: [
    TankCodeSelectDialogComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    EmptyMessageModule,
    LoadingSpinnerModule,
    LoadingOverlayModule,
    FilterPanelModule,
    PipesModule
  ],
  exports: [
    TankCodeSelectDialogComponent
  ]
})
export class TankCodeSelectDialogModule { }
