import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { AuthState } from '@shared/store/auth/auth.state';
import { Store } from '@ngxs/store';
import {AppConfigService} from '@shared/services/app-config.service';

@Injectable({
  providedIn: 'root'
})
export class BetaModuleGuard implements CanActivate {

  constructor(
    private store: Store,
    private router: Router,
    private appService: AppConfigService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    return this.appService.appConfig.showBetaModule;
  }
}
