import {UserDetails} from '@shared/models/user-details.model';
import {CompanySettingModel} from '@shared/models/companySettingModel';

export class Login {
  static readonly type = '[Auth] Login';

  constructor(public payload: { login: string, password: string, saveSession: boolean }) {
  }
}

export class LoginSuccess {
  static readonly type = '[Auth] Login Success';

  constructor(public payload: { userDetails: UserDetails, jwt: string, refreshToken: string, companySettings: CompanySettingModel }) {
  }
}

export class RfidLogin {
  static readonly type = '[Auth] RFIDLogin';

  constructor(public payload: { rfidTagSerial: string }) {
  }
}

export class Impersonate {
  static readonly type = '[Auth] impersonate';

  constructor(public payload: { userDetails: UserDetails, jwt: string, refreshToken: string, companySettings: CompanySettingModel }) {
  }

}

export class LoginError {
  static readonly type = '[Auth] Login Error';

  constructor(public payload: { error: any }) {
  }
}

export class Logout {
  static readonly type = '[Auth] Logout';
}

export class RefreshLogin {
  static readonly type = '[Auth] RefreshLogin';
}

export class RefreshSuccess {
  static readonly type = '[Auth] Refresh Success';

  constructor(public payload: { jwt: string }) {
  }
}

export class CheckLogin {
  static readonly type = '[Auth] check Login';

  constructor() {
  }
}

export class UpdatePermissions {
  static readonly type = '[Auth] permission update';

  constructor(public payload: { userId: number }) {
  }
}

export class UpdateCompanySettings {
  static readonly type = '[Auth] companySettings update';

  constructor(public payload: { companyId: number }) {
  }
}

export class UpdateModules {
  static readonly type = '[Auth] modules update';

  constructor(public payload: { userId: number }) {
  }
}

export class UpdateAuthState {
  static readonly type = '[Auth] update auth state';

  constructor() {
  }
}
