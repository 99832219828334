import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {TableColumnDialogComponent} from '@shared/components/table-column-dialog/table-column-dialog.component';
import {MaterialModule} from '@shared/material.module';
import {PipesModule} from '@shared/pipes/pipes.module';
import {FlexLayoutModule} from '@angular/flex-layout';



@NgModule({
  declarations: [TableColumnDialogComponent],
  imports: [
    CommonModule,
    MaterialModule,
    PipesModule,
    FlexLayoutModule
  ],
  exports: [
    TableColumnDialogComponent
  ]
})
export class TableColumnDialogModule { }
