import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GhsCategorySelectComponent } from './ghs-category-select.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MaterialModule} from '@shared/material.module';
import {PipesModule} from '@shared/pipes/pipes.module';
import {FlexLayoutModule} from '@angular/flex-layout';



@NgModule({
  declarations: [
    GhsCategorySelectComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    ReactiveFormsModule,
    PipesModule,
    FlexLayoutModule,
  ],
  exports: [
    GhsCategorySelectComponent
  ]
})
export class GhsCategorySelectModule { }
